import { TCLASIFICACION_SERVICIO_CONTRATADO } from '@/utils/consts'

export default {
  async selectSistemaCuota (Vue, idsistemaCuota) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.sistema_cuota)
      .innerJoin(
        tables.concepto_cuota,
        tables.sistema_cuota.idconcepto_cuota.eq(tables.concepto_cuota.idconcepto_cuota)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.sistema_cuota.idsistema_cuota.eq(idsistemaCuota),
          tables.sistema_cuota.estado.gt(0),
        )
      )
      .exec())[0]
  },
  async tipoServicioContratadoDeMantenimientoDeConceptoCuota(Vue, idconceptoCuota) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select()
      .from(tables.concepto_cuota_tservicio)
      .innerJoin(
        tables.tservicio_contratado,
        tables.concepto_cuota_tservicio.idtservicio_contratado.eq(tables.tservicio_contratado.idtservicio_contratado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.concepto_cuota_tservicio.idconcepto_cuota.eq(idconceptoCuota),
          tables.tservicio_contratado.estado.gt(0),
          tables.tservicio_contratado.idtclasificacion_servicio.eq(TCLASIFICACION_SERVICIO_CONTRATADO.clasificacion.mantenimiento),
        )
      )
      .exec()
  }
}
