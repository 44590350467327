<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template
          v-if="formattedItem.sistema_cuota && formattedItem.sistema_cuota.ffin_servicio"
          slot="extraSubtitle"
        >
          <v-chip
            label
            small
            color="error"
          >
            Fin de servicio {{ formattedItem.sistema_cuota.ffin_servicio|shortDate }}
          </v-chip>
        </template>
      </b10-view-summary>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SistemaCuotaViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'concepto_cuota.descripcion_cuota', label: 'Concepto de cuota' },
        {
          name: 'sistema_cuota.fcontratacion',
          label: 'Contratación',
          filter: this.$options.filters.shortDate,
        },
        {
          name: 'sistema_cuota.fproxima_cuota',
          label: 'Próxima cuota',
          filter: this.$options.filters.shortDate,
        },
        {
          name: 'sistema_cuota.ffin_cuota',
          label: 'Fin de cuotas',
          filter: this.$options.filters.shortDate,
        },
        {
          name: 'sistema_cuota.ffin_servicio',
          label: 'Fin de servicio',
          filter: this.$options.filters.shortDate,
        },
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.sistema_cuota.idsistema_cuota
        item.title = item.concepto_cuota.descripcion_cuota
        item.subtitle = `Contratación: ${this.$options.filters.shortDate(item.sistema_cuota.fcontratacion)}`
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.concepto_cuota.descripcion_cuota
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectSistemaCuota(this, this.routeParams.idsistema_cuota)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          const serviciosContratados = await Data.tipoServicioContratadoDeMantenimientoDeConceptoCuota(
            this,
            this.item.dataset.concepto_cuota.idconcepto_cuota
          )
          const tservicioContratado = _.map(serviciosContratados, 'tservicio_contratado')
          this.moreInfoRows.push({
            label: 'Tipo de servicio',
            value: _.map(tservicioContratado, 'descripcion').join(', '),
          })
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
  }
}
</script>
